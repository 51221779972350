<div class="card">
  <div class="card-header">
    <h1>Accept Negotation</h1>
  </div>
  <div class="card-body">

    <form class="form" name="form" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
      <div class="form-group">
        <div class="row">

          <div class="col-xl-12 col-lg-12">
            <mat-form-field appearance="fill" *ngIf="alignType?.length > 0">
              <mat-label>Alignment Type</mat-label>
              <mat-select name="alignment" #alignment="ngModel" [(ngModel)]="form.alignment" required>
                <mat-option *ngFor="let align of alignType" [value]="align.value">
                  {{align.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>


        <div class="row">
          <div class="form-group col-12">
            <label for="comment">Comment</label>
            <textarea class="form-control" 
            rows="3" 
            name="comment" 
            #comment="ngModel"
            maxlength="300"
              [(ngModel)]="form.comment"></textarea>
              <div class="text-muted" style="width:100%"
              *ngIf="form.comment?.length >= 300 && (comment.dirty || comment.touched)">
           Comment cannot exceed 300 characters.
         </div>
            <!-- <div
              class="text-muted" style="width:100%"
              *ngIf="f.submitted && !form.comment">
              Comment is required!
              </div> -->
          </div>
        </div>
        <!-- Adding Check Box to Change the Draft To Normal Track -->
        <div class="row" *ngIf="form.isFastTrack">
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="ToNoramlTrack" name="ToNoramlTrack"
                [(ngModel)]="form.ToNoramlTrack" />
              <label class="form-check-label" for="flexCheckDefault"> Transfare To Noraml Track </label>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="form.ToNoramlTrack && form.isFastTrack">
          <div class="form-group col-12">
            <label for="comment">Transfare To Normal Comment *</label>
            <textarea class="form-control" rows="3" name="transfareComment"
              placeholder="Why you need to transfare it to Normal Track" #transfareComment="ngModel"
              [(ngModel)]="form.transfareComment"></textarea>
            <div class="text-muted" style="width:100%" *ngIf="f.submitted && !form.transfareComment">
              Comment is required!
            </div>
          </div>
        </div>


        <div class="row">
          <div class="form-group col-12">
            <label for="file">Attach File</label>
            <input type="file" class="form-control-file" name="file" required placeholder="upload document"
              (change)="onFileSelect($event)" required
              accept=".pdf, .doc, .docx">
            <div class="text-muted" style="width:100%" *ngIf="!form.file">
              Attached File is required!
            </div>
          </div>
        </div>

      </div>
      <div>
        <button (click)="confirmWithCommercial()" class="btn btn-primary btns confirmCommercial">Confirm With
          Requester</button>
        <button (click)="confirm()" class="btn btn-success btns btnsSide confirmCommercial">Proceed To Approval</button>
        <button class="btn btn-danger btns btnsSide" (click)="dialogRef.close(false)">Cancel</button>
      </div>
    </form>

  </div>

</div>
